<template>
  <div :class="containerClass">
    <b-button
      :variant="btnVariant"
      block
      :disabled="disabled"
      @click="scanQRCode"
    >
      <span>{{ btnTitle }}</span>
      <b-icon
        icon="camera"
        v-if="showCameraIcon"
        :variant="cameraIconVariant"
        :class="{ 'ml-3': !!btnTitle }"
        font-scale="1.5"
      ></b-icon>
    </b-button>
    <b-modal
      id="qr-reader-modal"
      v-model="startScan"
      @hidden="modalClosed"
      @shown="modalShown"
      centered
      hide-footer
      modal-class="body-class"
    >
      <template #modal-header="{ close }">
        <h2 class="m-0 w-100 text-primary w-100 text-center font-weight-bolder">
          {{ displayDuration }}
        </h2>

        <button type="button" aria-label="Close" @click="close()" class="close">
          ×
        </button>
      </template>
      <div
        class="d-flex flex-column align-items-center px-5"
        style="margin-top: 75px;"
        v-if="error"
      >
        <b-alert show class="mb-4" variant="danger">
          <b-icon icon="exclamation-triangle" class="text-white mr-2"></b-icon>
          {{
            $te(`qrcodeReader.${error}`)
              ? this.$t(`qrcodeReader.${error}`)
              : this.$t("FORM.ERROR")
          }}</b-alert
        >
        <b-alert show variant="warning" v-if="error === 'NotAllowedError'">
          <p>
            <strong>{{ $t("COMMON.reminder") }}: </strong>
            {{ $t("qrcodeReader.google_chrome_warning") }}
          </p>
        </b-alert>
      </div>
      <qrcode-stream
        v-else
        :camera="camera"
        @decode="onDecode"
        :track="paintOutline"
        @init="onInit"
      >
        <div
          class="d-flex flex-1 align-items-center justify-content-center h-100"
          v-if="loading"
        >
          <b-icon
            icon="camera"
            font-scale="8"
            variant="warning"
            animation="cylon"
            class="mb-4"
          ></b-icon>
        </div>
      </qrcode-stream>
    </b-modal>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { QrcodeStream },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: "col-12 col-md-4 mt-md-0 mt-4"
    },
    btnTitle: {
      type: String,
      default: ""
    },
    showCameraIcon: {
      type: Boolean,
      default: false
    },
    cameraIconVariant: {
      type: String,
      default: "secondary"
    },
    btnVariant: {
      type: String,
      default: "outline-success"
    }
  },
  data() {
    return {
      loading: false,
      camera: "auto",
      startScan: false,
      result: null,
      timer: null,
      scanDuration: 0,
      displayDuration: "05:00",
      error: null
    };
  },
  methods: {
    startTimer() {
      this.scanDuration = 1000 * 60 * 5;
      this.checkRemaining();
      this.timer = setInterval(this.checkRemaining, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.scanDuration = 0;
      this.displayDuration = "05:00";
      this.startScan = false;
    },
    checkRemaining() {
      this.scanDuration -= 1000;
      this.displayDuration = this.$moment(this.scanDuration).format("mm:ss");

      if (this.scanDuration <= 0) {
        this.stopTimer();
      }
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    scanQRCode() {
      this.startScan = true;
      this.error = null;
      this.turnCameraOn();
    },
    async onInit(promise) {
      this.loading = true;
      this.error = null;
      try {
        await promise;
        this.startTimer();
      } catch (error) {
        // console.error(error);
        this.error = error?.name;
        this.displayDuration = "";
      } finally {
        this.loading = false;
      }
    },
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      this.startScan = false;
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    turnCameraOff() {
      this.camera = "off";
    },
    modalClosed() {
      this.stopTimer();
      this.error = null;
      this.$emit("qrcode-scanned", this.result);
    },
    modalShown() {
      this.error = null;
    }
  }
};
</script>
